@import 'utils/_variables';

/*
 * This 'volta-page' class was created for the new Volta's website 2020 version.
 * Some original styling was kept, and some components within the new pages use these original style files
 * (like 'general.sass' with its buttons classes).
 */
.volta-page {
  h1, h2, h3 {
    font-family: $quicksand;
    font-weight: 600; }

  h2:not(.h1), h3 {
    color: $dark-grey-2; }

  h1, .h1 {
    font-size: 50px;

    @media (max-width: $sm-max) {
      font-size: 18px; } }

  h2:not(.h1), h3.h2 {
    font-size: 26px;
    line-height: 36px;

    @media (max-width: $sm-max) {
      font-size: 16px; }

    &.dark-green {
      color: $dark-green; } }

  h3 {
    margin-bottom: 30px; }

  h3.margin-top-only {
    margin-bottom: 0;
    margin-top: 30px; }

  p, ul, ol {
    line-height: 34px;
    font-size: 20px;

    @media (max-width: $sm-max) {
      line-height: 18px;
      font-size: 12px; } }

  * {
    &.no-margin-bottom {
      margin-bottom: 0 !important; } }

  &.white-background {
    background-color: $white; }

  .catchphrase-section, .buttons-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 60px 0;
    background-color: $white;

    @media (max-width: $sm-max) {
      padding: 20px 0; } }

  .catchphrase-section {
    flex-direction: column;

    h2:not(.h1), h3.h2 {
      max-width: 1200px;
      width: 70%;
      text-align: center;
      padding: 0 8px;
      font-family: $roboto !important;

      @media (max-width: $sm-max) {
        font-size: 16px;
        width: 90%;
        line-height: 30px; } }

    &.small-vertical-padding {
      padding: 10px 0; } }

  .catchphrase-small-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;

    h3 {
      max-width: 800px;
      line-height: 140%;
      text-align: center;
      font-size: 20px !important;
      font-family: $roboto !important;
      margin-bottom: 0;

      @media (max-width: $sm-max) {
        font-size: 14px !important; } } }

  .buttons-section {
    flex-wrap: wrap;

    a {
      margin: 20px;

      &.smaller-margin {
        margin: 10px; } }

    &.small-padding-top {
      padding-top: 20px; }

    &.small-padding-bottom {
      padding-bottom: 20px; }

    &.no-vertical-padding {
      padding-top: 0;
      padding-bottom: 0; }

    @media (max-width: $sm-max) {
      a {
        margin: 12px 20px; }

      &.single-column {
        flex-direction: column; } } }

  .text-section.light-grey {
    background-color: $light-grey-2;

    .buttons-section {
      background-color: $light-grey-2; } }

  .text-section.white {
    background-color: $white; }

  .text-section {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $sm-max) {
      padding: 10px 0; }

    > .catchphrase-small-section {
      padding-bottom: 40px;
      @media (max-width: $sm-max) {
        padding: 40px 30px 10px; } }

    h1, .h1 {
      color: $dark-green;
      margin: 40px 10px;

      &.dark-grey {
        color: $dark-grey-2; }

      &.orange {
        color: $orange; }

      @media (max-width: $sm-max) {
        text-align: center;
        font-size: 18px;
        margin: 20px 10px; } }

    h2:not(.h1), h3.h2 {
      margin-bottom: 20px;
      &.big-margin-top {
        margin-top: 60px; } }

    h3 {
      font-size: 25px;

      @media (max-width: $sm-max) {
        font-size: 15px; } }

    h3.in-text {
      margin-top: 30px; }

    ul, ol {
      margin-left: 40px;

      &.items-with-padding-bottom {
        li {
          padding-bottom: 16px; } }

      @media (max-width: $sm-max) {
        margin-left: 20px; } }

    &.no-padding-bottom {
      padding-bottom: 0; }

    &.no-padding-top {
      padding-top: 0; }

    .two-columns-wrapper#battlefield-is-data {
      width: 1040px;
      .text-box {
        height: 560px;
        justify-content: space-between;
        padding-bottom: 0;

        p {
          margin-bottom: 0; }

        @media (max-width: 1280px) {
          height: auto;
          justify-content: normal;

          p {
            margin-bottom: 20px; } } } }

    .two-columns-wrapper#volta-app-features {
      img {
        width: 444px; } }

    .two-columns-wrapper#ai-for-utilities {
      img {
        width: 432px;
        align-self: flex-start;
        padding: 10px 0 10px;

        @media (max-width: $sm-max) {
          padding: 10px 30px; } } }

    .two-columns-wrapper#voltaware-sensors-compatility {
      margin-top: 40px;
      div {
        width: 380px;
        display: flex;
        justify-content: center; }

      img {
        width: 280px; } }

    .two-columns-wrapper.narrow-text-box {
      .text-box {
        flex: 1 1 26%; }

      h2:not(.h1), h3.h2 {
        font-size: 30px;
        margin-bottom: 10px;

        @media (max-width: $sm-max) {
          font-size: 18px;
          text-align: center; } }

      p {
        margin-bottom: 50px;

        @media (max-width: $sm-max) {
          margin-bottom: 30px; } } }

    .two-columns-wrapper.large-wrapper {
      width: 76%; }

    .two-columns-wrapper.medium-wrapper {
      width: 952px; }

    .two-columns-wrapper.two-images {
      img {
        width: auto; } }

    .two-columns-wrapper {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      max-width: 1200px;
      width: 70%;
      flex-wrap: wrap;

      @media (max-width: $sm-max) {
        width: 100% !important; }

      img {
        flex-grow: 0;
        flex-shrink: 0;
        width: 256px;
        max-width: 100%;
        padding: 10px 10px 10px;

        @media (max-width: $sm-max) {
          padding: 10px 50px 10px; }

        &.no-padding-top {
          padding-top: 0; } }

      .text-box {
        padding: 0 40px 40px;
        flex: 1 1 70%;
        display: flex;
        flex-direction: column;

        @media (max-width: $sm-max) {
          padding: 20px 30px 10px; }

        h3:not(.h2), button, a {
          align-self: center; } }

      &.large-image {
        img {
          width: 380px; }

        .text-box {
          flex: 1 1 60%; } }

      &.extra-large-image {
        img {
          width: 500px; }

        .text-box {
          flex: 1 1 50%; } }

      &.wrap-reverse {
        flex-wrap: wrap-reverse;
        align-items: flex-end; } }

    .single-column-wrapper.medium-wrapper {
      width: 952px;
      max-width: 100%; }

    .single-column-wrapper.small-wrapper {
      width: 688px;
      max-width: 100%; }

    .single-column-wrapper#introducing-voltaware {
      p {
        font-size: 24px;

        @media (max-width: $sm-max) {
            font-size: 12px;
            line-height: 18px; } } }

    .single-column-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1200px;
      width: 70%;
      padding: 0 20px;

      @media (max-width: $sm-max) {
        width: 100%;
        padding: 0 30px; }

      p {
        align-self: flex-start; }

      img {
        padding: 60px 20px;
        max-width: 72%;

        &.small-padding-top {
          padding-top: 10px; }

        &.small-padding-bottom {
          padding-bottom: 10px; }

        &.large-image {
          max-width: 90%; }

        @media (max-width: $sm-max) {
          padding: 40px 0px;
          max-width: 100% !important; } }


      ul, ol {
        margin-bottom: 60px;
        min-width: 600px;

        @media (max-width: $sm-max) {
          min-width: auto; } }

      &.light-grey {
        background-color: $light-grey-2;

        .buttons-section {
          background-color: $light-grey-2; } }

      &.with-vertical-padding {
        padding: 40px 20px; }

      &.full-width-lists {
        ul, ol {
          width: 100%;
          padding-left: 40px; } }

      &.all-centred {
        * {
          align-self: center; } } } } }


